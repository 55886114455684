'use client';

import React, { ReactNode } from 'react';
import { motion, Variants } from 'framer-motion';


interface Props {
  children: ReactNode;
}

const FadeInRight: Variants = {
  hide: {
    opacity: 0,
    x: '100%',
  },
  show: {
    opacity: 1,
    x: '0%',
    transition: {
      duration: 0.8,
    },
  },
};

export function FadeIn({ children }: Props) {
  return (
    <>
      <motion.div
        initial='hide'
        whileInView='show'
        viewport={{ once: true }}
        variants={FadeInRight}
      >
        {children}
      </motion.div>
    </>
  );
}